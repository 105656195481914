import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Footer = () => (
  <footer>
    <p>© {new Date().getFullYear()} Nick Gagne. All rights reserved.</p>
  </footer>
)

export default Footer
