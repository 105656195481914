import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import logo from '../../content/assets/logo-dark.png';
import logo2x from '../../content/assets/logo-dark@2x.png';

const Header = () => (
  <header>
    <div>
      <h1>
        <Link to="/"><img src={logo} srcSet={`${logo}, ${logo2x} 2x`} alt="AppDevRocks" /></Link>
      </h1>
      <nav>
        <Link to="/about"><span>About</span></Link>
        <a href="https://www.youtube.com/channel/UCiCS0TI47ceUAhvl_U0Dw9A/"><span>YouTube</span></a>
        {/*<Link to="/contact"><span>Contact</span></Link>*/}
      </nav>
    </div>
  </header>
);

export default Header
