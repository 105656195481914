import React from 'react'

import Header from './header'
import Footer from './footer'
import '../scss/layout.scss';
import Helmet from 'react-helmet';

class Layout extends React.Component {
  render(data) {
    const { children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700,700i" rel="stylesheet"/>
        </Helmet>

        <Header/>
        <div className="page__outer">
          <div className="page__inner">
            {children}
          </div>
        </div>
        <Footer/>
      </>
    )
  }
}

export default Layout
